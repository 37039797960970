import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '@/layouts/Auth'
import SignatureLayout from '@/layouts/Signature'
import MainLayout from '@/layouts/Main'
import store from '@/store'

Vue.use(Router)

const router = new Router({
  base: process.env.BASE_URL,
  // mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: 'dashboard/news',
      component: MainLayout,
      meta: {
        authRequired: true,
        hidden: true,
      },
      children: [
        // Dashboards
        {
          path: '/dashboard/news',
          meta: {
            title: 'Dashboard Nyheter',
          },
          component: () => import('./views/dashboard/news'),
        },
        {
          path: '/dashboard/alpha',
          meta: {
            title: 'Dashboard Alpha',
          },
          component: () => import('./views/dashboard/alpha'),
        },
        {
          path: '/dashboard/beta',
          meta: {
            title: 'Dashboard Beta',
          },
          component: () => import('./views/dashboard/beta'),
        },
        {
          path: '/dashboard/crypto',
          meta: {
            title: 'Dashboard Crypto',
          },
          component: () => import('./views/dashboard/crypto'),
        },
        {
          path: '/dashboard/gamma',
          meta: {
            title: 'Dashboard Gamma',
          },
          component: () => import('./views/dashboard/gamma'),
        },
        // Education
        {
          path: 'education',
          meta: {
            title: 'Opplæring',
          },
          component: () => import('./views/education'),
        },
        // Reports
        {
          path: 'reports',
          meta: {
            title: 'Rapporter',
          },
          component: () => import('./views/reports'),
        },
        {
          path: 'reports/settings',
          meta: {
            title: 'Instillinger Rapporter',
          },
          component: () => import('./views/reports/settings'),
        },
        {
          path: 'reports/view/:id',
          meta: {
            title: 'Rapporter',
          },
          component: () => import('./views/reports/viewReport'),
        },
        // Talkmore B2C
        {
          path: 'talkmoreB2C/newSale',
          meta: {
            title: 'Talkmore Privat | Ny Salg',
          },
          component: () => import('./views/talkmoreB2C/newSale'),
        },
        {
          path: 'talkmoreB2C/sales/:id',
          meta: {
            title: 'Talkmore Privat | Endre Salg',
          },
          component: () => import('./views/talkmoreB2C/editSale'),
        },
        {
          path: 'talkmoreB2C/sales',
          meta: {
            title: 'Talkmore Privat | Salgsoversikt',
          },
          component: () => import('./views/talkmoreB2C/salesList'),
        },
        {
          path: 'talkmoreB2C/epusers',
          meta: {
            title: 'Talkmore Privat | EP Brukere',
          },
          component: () => import('./views/talkmoreB2C/epusers'),
        },
        {
          path: 'talkmoreB2C/settings',
          meta: {
            title: 'Talkmore Privat | Produkter',
          },
          component: () => import('./views/talkmoreB2C/settings'),
        },
        {
          path: 'talkmoreB2C/report',
          meta: {
            title: 'Talkmore Privat | Rapport',
          },
          component: () => import('./views/talkmoreB2C/salesReport'),
        },
        {
          path: 'talkmoreB2C/products',
          meta: {
            title: 'Talkmore Privat | Produkter',
          },
          component: () => import('./views/talkmoreB2C/products'),
        },
        {
          path: 'talkmoreB2C/infoweb',
          beforeEnter() { location.href = 'https://skycallasno.sharepoint.com/:u:/r/sites/TalkmorePrivatTMFM/SitePages/Home.aspx?csf=1&web=1&share=EYpuY6yn_89Lj_qRZcwRXGgBsynEA38WKIO9C3SvEKANAw&e=hv3lFe' },
        },
        // Tools
        {
          path: 'tools/carrierlookup',
          meta: {
            title: 'Verktøy | Carrier Lookup',
          },
          component: () => import('./views/tools/carrierLookup'),
        },
        {
          path: 'tools/supporttables',
          meta: {
            title: 'Verktøy | Tabeller',
          },
          component: () => import('./views/tools/supportTables'),
        },
        {
          path: 'tools/supporttables/settings',
          meta: {
            title: 'Verktøy | Tabeller',
          },
          component: () => import('./views/tools/supportTables/settings'),
        },
        {
          path: 'tools/supporttables/edit/:id',
          meta: {
            title: 'Verktøy | Tabeller',
          },
          component: () => import('./views/tools/supportTables/editTable'),
        },
        {
          path: 'tools/signatureGenerator',
          meta: {
            title: 'Verktøy | Generer Signatur',
          },
          component: () => import('./views/tools/emailSignature'),
        },
        {
          path: 'tools/HeicConverter',
          meta: {
            title: 'Verktøy | HEIC convertering',
          },
          component: () => import('./views/tools/heic2jpg'),
        },
        {
          path: 'tools/frontPageLetter',
          meta: {
            title: 'Verktøy | Førsteside brev',
          },
          component: () => import('./views/tools/frontPageLetter'),
        },
        // Tasks / approval
        {
          path: 'tasks',
          meta: {
            title: 'Administrasjon | Oppgaver',
          },
          component: () => import('./views/tasks/tasksList'),
        },
        {
          path: 'tasksdone',
          meta: {
            title: 'Administrasjon | Oppgaver',
          },
          component: () => import('./views/tasks/tasksListDone'),
        },
        // esignature module
        {
          path: 'sign',
          meta: {
            title: 'Administrasjon | Signeringer',
          },
          component: () => import('./views/esign/signatureList'),
        },
        {
          path: 'sign/new',
          meta: {
            title: 'Administrasjon | Signeringer',
          },
          component: () => import('./views/esign/newEsign'),
        },
        {
          path: 'tasks/:id',
          meta: {
            title: 'Oppgave',
          },
          component: () => import('./views/tasks/task'),
        },
        // HR
        {
          path: 'hr/birthdays',
          meta: {
            title: 'Bursdager',
          },
          component: () => import('./views/HR/birthdays'),
        },
        {
          path: 'hr/contacts',
          meta: {
            title: 'Kontakter',
          },
          component: () => import('./views/HR/contacts'),
        },
        {
          path: 'hr/persons/:status',
          meta: {
            title: 'HR Personer',
          },
          component: () => import('./views/HR/personer'),
        },
        {
          path: 'hr/person/:uid',
          meta: {
            title: 'HR About User',
          },
          component: () => import('./views/HR/personer/about-user'),
        },
        {
          path: 'hr/onboarding',
          meta: {
            title: 'HR Onboarding',
          },
          component: () => import('./views/HR/onboarding'),
        },
        {
          path: 'hr/newOnboarding',
          meta: {
            title: 'HR Ny Onboarding',
          },
          component: () => import('./views/HR/newOnboarding'),
        },
        // HR Settings
        {
          path: 'hr/settings',
          meta: {
            title: 'HR Instillinger',
          },
          component: () => import('./views/HR/settings'),
        },
        {
          path: 'hr/settings/contracts/new',
          meta: {
            title: 'HR Instillinger | Ny standardkontrakt',
          },
          component: () => import('./views/HR/settings/contracts/newContract'),
        },
        {
          path: 'hr/settings/contracts/edit/:id',
          meta: {
            title: 'HR Instillinger | Endre kontrakt',
          },
          component: () => import('./views/HR/settings/contracts/editContract'),
        },
        // Documents
        {
          path: '/documents',
          meta: {
            title: 'Documents',
          },
          component: () => import('./views/documents'),
        },
        // Profile
        {
          path: '/profile',
          meta: {
            title: 'Profile',
          },
          component: () => import('./views/profile'),
        },
        // Employeehandbook
        {
          path: '/employeehandbook',
          meta: {
            title: 'Personal håndbok',
          },
          component: () => import('./views/handbook'),
        },
        {
          path: '/employeehandbook/feedback',
          meta: {
            title: 'Varsling og tilbakemeldinger',
          },
          component: () => import('./views/feedback'),
        },
        // CMS
        {
          path: '/cms/new-post',
          meta: {
            title: 'Ny innlegg',
          },
          component: () => import('./views/news/newPost'),
        },
        {
          path: '/cms/edit-post/:id',
          meta: {
            title: 'Endre innlegg',
          },
          component: () => import('./views/news/editPost'),
        },
        {
          path: '/cms/posts',
          meta: {
            title: 'Posts',
          },
          component: () => import('./views/news/posts'),
        },
        // Timewriting
        {
          path: '/timesheet/manager/',
          meta: {
            title: 'Timelister',
          },
          component: () => import('./views/timesheet/managerTimeSheet'),
        },
        // Payroll
        {
          path: '/payroll/calculation/:payrollId',
          meta: {
            title: 'Lønnskjøring',
          },
          component: () => import('./views/payroll/calculation'),
        },
        {
          path: '/payroll/payrolls',
          meta: {
            title: 'Ny Lønnskjøring',
          },
          component: () => import('./views/payroll/payrolls'),
        },
        {
          path: '/payroll/payslips',
          meta: {
            title: 'Lønnsslipper',
          },
          component: () => import('./views/payroll/payslips'),
        },
        {
          path: '/payroll/my-payslips',
          meta: {
            title: 'Min lønn',
          },
          component: () => import('./views/payroll/myPayslips'),
        },
        {
          path: '/payroll/transactions',
          meta: {
            title: 'Transaksjoner',
          },
          component: () => import('./views/payroll/transactions'),
        },
        {
          path: '/payroll/consultants',
          meta: {
            title: 'Konsulenter',
          },
          component: () => import('./views/payroll/consultants'),
        },
        {
          path: '/payroll/bonus',
          meta: {
            title: 'Bonus',
          },
          component: () => import('./views/payroll/bonus'),
        },
        {
          path: '/payroll/recruitment',
          meta: {
            title: 'Rekruttering',
          },
          component: () => import('./views/payroll/recruitment'),
        },
        {
          path: '/payroll/import',
          meta: {
            title: 'Import',
          },
          component: () => import('./views/payroll/import'),
        },
        {
          path: '/payroll/sickleave',
          meta: {
            title: 'Sykdom',
          },
          component: () => import('./views/payroll/sickleave'),
        },
        {
          path: '/payroll/guaranteedSalary',
          meta: {
            title: 'Garantilønn',
          },
          component: () => import('./views/payroll/guaranteedSalary'),
        },
        {
          path: '/payroll/settings',
          meta: {
            title: 'Payroll Settings',
          },
          component: () => import('./views/payroll/settings'),
        },
        // Helpdesk
        {
          path: '/helpdesk/dashboard',
          meta: {
            title: 'Helpdesk Dashboard',
          },
          component: () => import('./views/helpdesk/dashboard'),
        },
        {
          path: '/helpdesk/board',
          meta: {
            title: 'Helpdesk Board',
          },
          component: () => import('./views/helpdesk/board'),
        },
        {
          path: '/helpdesk/chat',
          meta: {
            title: 'Helpdesk Chat',
          },
          component: () => import('./views/helpdesk/chat'),
        },
        {
          path: '/helpdesk/new-ticket',
          meta: {
            title: 'Helpdesk | Ny sak',
          },
          component: () => import('./views/helpdesk/newTicket'),
        },
        {
          path: '/helpdesk/settings',
          meta: {
            title: 'Helpdesk Dashboard',
          },
          component: () => import('./views/helpdesk/dashboard'),
        },
        // Links
        {
          path: '/office365',
          beforeEnter() { location.href = 'https://portal.office.com' },
        },
        {
          path: '/asana',
          beforeEnter() { location.href = 'https://app.asana.com' },
        },
        {
          path: '/visma',
          beforeEnter() { location.href = 'https://home.visma.com' },
        },
        {
          path: '/jamf',
          beforeEnter() { location.href = 'https://app.jamfnow.com' },
        },
        // Settings
        {
          path: '/my-settings',
          meta: {
            title: 'Mine Instillinger',
          },
          component: () => import('./views/mySettings'),
        },
        {
          path: '/settings',
          meta: {
            title: 'Instillinger',
          },
          component: () => import('./views/settings/company'),
        },
        {
          path: '/tv/settings',
          meta: {
            title: 'TV Instillinger',
          },
          component: () => import('./views/TV/settings'),
        },
        {
          path: '/settings/integration',
          meta: {
            title: 'Integrasjoner',
          },
          component: () => import('./views/settings/integration'),
        },
        // Ecommerce
        {
          path: '/ecommerce/dashboard',
          meta: {
            title: 'Ecommerce Dashboard',
          },
          component: () => import('./views/ecommerce/dashboard'),
        },
        {
          path: '/ecommerce/orders',
          meta: {
            title: 'Ecommerce Orders',
          },
          component: () => import('./views/ecommerce/orders'),
        },
        {
          path: '/ecommerce/product-catalog',
          meta: {
            title: 'Ecommerce Product Catalog',
          },
          component: () => import('./views/ecommerce/product-catalog'),
        },
        {
          path: '/ecommerce/product-details',
          meta: {
            title: 'Ecommerce Product Details',
          },
          component: () => import('./views/ecommerce/product-details'),
        },
        {
          path: '/ecommerce/cart',
          meta: {
            title: 'Ecommerce Cart',
          },
          component: () => import('./views/ecommerce/cart'),
        },
        // Widgets
        {
          path: '/widgets/general',
          meta: {
            title: 'Widgets / General',
          },
          component: () => import('./views/widgets/general'),
        },
        {
          path: '/widgets/lists',
          meta: {
            title: 'Widgets / Lists',
          },
          component: () => import('./views/widgets/lists'),
        },
        {
          path: '/widgets/tables',
          meta: {
            title: 'Widgets / Tables',
          },
          component: () => import('./views/widgets/tables'),
        },
        {
          path: '/widgets/charts',
          meta: {
            title: 'Widgets / Charts',
          },
          component: () => import('./views/widgets/charts'),
        },
        // Apps
        {
          path: '/apps/calendar',
          meta: {
            title: 'Apps / Calendar',
          },
          component: () => import('./views/apps/calendar'),
        },
        {
          path: '/apps/digitalocean-create',
          meta: {
            title: 'Apps / DigitalOcean Create',
          },
          component: () => import('./views/apps/digitalocean-create'),
        },
        {
          path: '/apps/digitalocean-droplets',
          meta: {
            title: 'Apps / DigitalOcean Droplets',
          },
          component: () => import('./views/apps/digitalocean-droplets'),
        },
        {
          path: '/apps/gallery',
          meta: {
            title: 'Apps / Gallery',
          },
          component: () => import('./views/apps/gallery'),
        },
        {
          path: '/apps/github-discuss',
          meta: {
            title: 'Apps / GitHub Discuss',
          },
          component: () => import('./views/apps/github-discuss'),
        },
        {
          path: '/apps/github-explore',
          meta: {
            title: 'Apps / GitHub Explore',
          },
          component: () => import('./views/apps/github-explore'),
        },
        {
          path: '/apps/google-analytics',
          meta: {
            title: 'Apps / Google Analytics',
          },
          component: () => import('./views/apps/google-analytics'),
        },
        {
          path: '/apps/helpdesk-dashboard',
          meta: {
            title: 'Apps / Helpdesk Dashboard',
          },
          component: () => import('./views/apps/helpdesk-dashboard'),
        },
        {
          path: '/apps/jira-agile-board',
          meta: {
            title: 'Apps / Jira Agile Board',
          },
          component: () => import('./views/apps/jira-agile-board'),
        },
        {
          path: '/apps/jira-dashboard',
          meta: {
            title: 'Apps / Jira Dashboard',
          },
          component: () => import('./views/apps/jira-dashboard'),
        },
        {
          path: '/apps/mail',
          meta: {
            title: 'Apps / Mail',
          },
          component: () => import('./views/apps/mail'),
        },
        {
          path: '/apps/messaging',
          meta: {
            title: 'Apps / Mesagging',
          },
          component: () => import('./views/apps/messaging'),
        },
        {
          path: '/apps/profile',
          meta: {
            title: 'Apps / Profile',
          },
          component: () => import('./views/apps/profile'),
        },
        {
          path: '/apps/todoist-list',
          meta: {
            title: 'Apps / Todoist List',
          },
          component: () => import('./views/apps/todoist-list'),
        },
        // {
        //   path: '/apps/wordpress-post',
        //   meta: {
        //     title: 'Apps / Wordpress Post',
        //   },
        //   component: () => import('./views/apps/wordpress-post'),
        // },
        {
          path: '/apps/wordpress-posts',
          meta: {
            title: 'Apps / Wordpress Posts',
          },
          component: () => import('./views/apps/wordpress-posts'),
        },
        // // Cards
        {
          path: '/cards/basic-cards',
          meta: {
            title: 'Cards / Basic',
          },
          component: () => import('./views/cards/basic-cards'),
        },
        {
          path: '/cards/tabbed-cards',
          meta: {
            title: 'Cards / Tabbed',
          },
          component: () => import('./views/cards/tabbed-cards'),
        },
        // UI Kits
        {
          path: '/ui-kits/bootstrap',
          meta: {
            title: 'UI Kit / Bootstrap',
          },
          component: () => import('./views/ui-kits/bootstrap'),
        },
        {
          path: '/ui-kits/antd',
          meta: {
            title: 'UI Kit / Ant Design',
          },
          component: () => import('./views/ui-kits/antd'),
        },
        // Tables
        {
          path: '/tables/bootstrap',
          meta: {
            title: 'Tables / Bootstrap',
          },
          component: () => import('./views/tables/bootstrap'),
        },
        {
          path: '/tables/antd',
          meta: {
            title: 'Tables / Ant Design',
          },
          component: () => import('./views/tables/antd'),
        },
        // Charts
        {
          path: '/charts/chartistjs',
          meta: {
            title: 'Charts / Chartist.js',
          },
          component: () => import('./views/charts/chartistjs'),
        },
        {
          path: '/charts/chartjs',
          meta: {
            title: 'Charts / Chart.js',
          },
          component: () => import('./views/charts/chartjs'),
        },
        {
          path: '/charts/c3',
          meta: {
            title: 'Charts / C3 Charts',
          },
          component: () => import('./views/charts/c3'),
        },
        // Icons
        {
          path: '/icons/feather-icons',
          meta: {
            title: 'Icons / Feather',
          },
          component: () => import('./views/icons/feather-icons'),
        },
        {
          path: '/icons/fontawesome',
          meta: {
            title: 'Icons / Fontawesome',
          },
          component: () => import('./views/icons/fontawesome'),
        },
        {
          path: '/icons/linearicons-free',
          meta: {
            title: 'Icons / Linearicons Free',
          },
          component: () => import('./views/icons/linearicons-free'),
        },
        {
          path: '/icons/icomoon-free',
          meta: {
            title: 'Icons / Icomoon Free',
          },
          component: () => import('./views/icons/icomoon-free'),
        },
        // Advanced
        {
          path: '/advanced/form-examples',
          meta: {
            title: 'Advanced / Form Examples',
          },
          component: () => import('./views/advanced/form-examples'),
        },
        {
          path: '/advanced/email-templates',
          meta: {
            title: 'Advanced / Email Templates',
          },
          component: () => import('./views/advanced/email-templates'),
        },
        {
          path: '/advanced/utilities',
          meta: {
            title: 'Advanced / Utilities',
          },
          component: () => import('./views/advanced/utilities'),
        },
        {
          path: '/advanced/grid',
          meta: {
            title: 'Advanced / Grid',
          },
          component: () => import('./views/advanced/grid'),
        },
        {
          path: '/advanced/typography',
          meta: {
            title: 'Advanced / Typography',
          },
          component: () => import('./views/advanced/typography'),
        },
        {
          path: '/advanced/pricing-tables',
          meta: {
            title: 'Advanced / Pricing Tables',
          },
          component: () => import('./views/advanced/pricing-tables'),
        },
        {
          path: '/advanced/invoice',
          meta: {
            title: 'Advanced / Invoice',
          },
          component: () => import('./views/advanced/invoice'),
        },
        {
          path: '/advanced/colors',
          meta: {
            title: 'Advanced / Colors',
          },
          component: () => import('./views/advanced/colors'),
        },
      ],
    },

    // System Pages
    {
      path: '/auth',
      component: AuthLayout,
      redirect: 'auth/login',
      children: [
        {
          path: '/auth/404',
          meta: {
            title: 'Error 404',
          },
          component: () => import('./views/auth/404'),
        },
        {
          path: '/auth/500',
          meta: {
            title: 'Error 500',
          },
          component: () => import('./views/auth/500'),
        },
        {
          path: '/auth/login',
          meta: {
            title: 'Sign In',
          },
          component: () => import('./views/auth/login'),
        },
        {
          path: '/tv',
          meta: {
            title: 'TV Login',
          },
          component: () => import('./views/TV/login'),
        },
      ],
    },
    {
      path: '/tv/landscape/:id',
      meta: {
        title: 'TV',
        authRequired: true,
        hidden: true,
      },
      component: () => import('./views/TV/landscape'),
      hidden: true,
    },
    {
      path: '/tv/landscape2/:id',
      meta: {
        title: 'TV',
        authRequired: true,
        hidden: true,
      },
      component: () => import('./views/TV/landscape2'),
      hidden: true,
    },
    {
      path: '/tv/portrait/:id',
      meta: {
        title: 'TV',
        authRequired: true,
        hidden: true,
      },
      component: () => import('./views/TV/portrait'),
      hidden: true,
    },
    {
      path: '/signresults',
      component: SignatureLayout,
      children: [
        {
          path: '/signresults/success',
          meta: {
            title: 'Signatur fullført',
          },
          component: () => import('./views/signresults/success'),
          hidden: true,
        },
        {
          path: '/signresults/cancel',
          meta: {
            title: 'Signatur avbrytet',
          },
          component: () => import('./views/signresults/cancel'),
          hidden: true,
        },
        {
          path: '/signresults/error',
          meta: {
            title: 'Signatur feilet',
          },
          component: () => import('./views/signresults/error'),
          hidden: true,
        },
      ],
    },
    // Redirect to 404
    {
      path: '*', redirect: 'auth/404', hidden: true,
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authRequired)) {
    if (!store.state.user.authorized) {
      next({
        path: '/auth/login',
        query: { redirect: to.fullPath },
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
